import { removeAllTags } from '@berlitz/max-utils/lib/sanitizeHtml'
import PortalLearningPathActivity from '@classes/LearningPathActivities'
import PortalLearningPathAttachment from '@classes/LearningPathAttachments'
import MyBerlitzLessons from '@classes/Lessons'
import PortalProgram from '@classes/Program'
import { LMSLearningPlansUnits } from '@interfaces/LearningPath'
import { MYTUTOR_BASE_URL } from '@utils/constants'
import {
  ACTIVITY_LINKS,
  CULTURE_ACTIVITY_TYPE,
  DETAILS_TYPE_DEFAULT,
  FLEX_ACTIVITY_LINK,
  LP_COURSE_META_CUSTOM_TITLE,
  LP_STATUS,
  LP_UNIT_LESSON_STATUS,
  LP_UNIT_TYPE,
  CURRICULUM_TEST_ACTIVITY_TYPE,
  LP_LANGUAGE_FRENCH,
} from '@utils/constants/learningPath'
import isNaN from 'lodash/isNaN'

class PortalLearningPathUnit {
  unit?: LMSLearningPlansUnits
  StudentProgram?: PortalProgram
  StudentSessions?: MyBerlitzLessons
  LCSCount?: number
  IsFlex?: boolean
  SelectedLanguage?: string
  CheckpointUrl?: string
  UnlimitedLCS?: boolean
  LearningPathId?: string
  IsJapanFlex?: boolean
  IsPreviousLP?: boolean
  isPastContract?: boolean
  DisableLCS: boolean
  IsFromElective: boolean
  IsFromFoundation: boolean
  PathContractEnded?: boolean
  Is3rdPartyMaterial?: boolean
  Is3rdPartyMaterialHasAttachments?: boolean
  IsIndustrySpecificContent?: boolean
  module?: number
  /**
   * Use to track the index order of the unit in the learning path
   */
  IndexOrder: number

  constructor(
    unit?: LMSLearningPlansUnits,
    StudentProgram?: PortalProgram,
    StudentSessions?: MyBerlitzLessons,
    LPId?: string,
    IsJapanFlex?: boolean,
    IsPreviousLP?: boolean,
    IsFromElective?: boolean,
    IsFromFoundation?: boolean,
    PathContractEnded?: boolean,
    IsIndustrySpecific?: boolean
  ) {
    this.unit = unit
    this.StudentProgram = StudentProgram
    this.StudentSessions = StudentSessions
    this.LCSCount = 0
    this.IsFlex = false
    this.LearningPathId = LPId
    this.IsJapanFlex = IsJapanFlex
    this.IsPreviousLP = IsPreviousLP
    this.DisableLCS = false
    this.IsFromElective = IsFromElective ?? false
    this.IsFromFoundation = IsFromFoundation ?? false
    this.PathContractEnded = PathContractEnded
    this.IndexOrder = 0
    this.module = unit?.module
    this.IsIndustrySpecificContent = IsIndustrySpecific ?? false
  }

  get Id() {
    return this.unit?.unitid ?? 0
  }

  get LPId() {
    return this.LearningPathId
  }

  getAttachments = (profileType: string) =>
    this.unit?.attachments
      .filter(({ type, url }) => !ACTIVITY_LINKS?.[profileType]?.omitAttachments?.includes(type) && url?.length)
      ?.map((attachments) => new PortalLearningPathAttachment(attachments, this.StudentProgram))

  // PTL-1263: this function ensures that Learning Materials are always on top in the display
  getOrderedActivities = (profileType: string) =>
    this.unit?.activities
      ?.map((activity) => new PortalLearningPathAttachment(activity, this.StudentProgram))
      .sort((a, b) => {
        return a.isTypedActivity(profileType) && b.isTypedActivity(profileType)
          ? 0
          : a.isTypedActivity(profileType) && !b.isTypedActivity(profileType)
            ? -1
            : 1
      })

  get Activities() {
    return this.unit?.activities?.map((activity) => {
      return new PortalLearningPathActivity(activity, this.StudentProgram)
    })
  }

  get IsCourseMetaData() {
    return this.unit?.unittype === LP_UNIT_TYPE.MATERIAL
  }

  get IsLessonZero() {
    return this.Type === LP_UNIT_TYPE.INSTRUCTIONAL && !this.IsCourseMetaData
  }

  get ResetCount() {
    return this.unit?.resetcount
  }

  get IsRedo() {
    return !!this.unit?.resetcount && this.FlexStatus === LP_STATUS.IN_PROGRESS
  }

  get UnitStatus() {
    return this.unit?.unitstatus || ''
  }

  get LessonStatus() {
    return this.unit?.lessonstatus
  }

  get FlexStatus() {
    return this.unit?.flexstatus || this.UnitStatus
  }

  get Status() {
    if (this.IsCourseMetaData) {
      return LP_STATUS.COURSE_META
    }

    let status = !this.IsFlex ? this.LessonStatus || this.UnitStatus : this.UnitStatus

    if (this.IsFlex && this.Type === LP_UNIT_TYPE.LESSON && this.unit?.resetcount && status !== LP_STATUS.COMPLETE) {
      status = LP_STATUS.COMPLETE
    }

    // lcs status will still be treated as complete if its session has passed and lesson reg has not yet been submitted
    // update(PTL-1582): include submitted lesson reg in calculating LCS completion status
    if (
      this.IsFlex &&
      this.Type === LP_UNIT_TYPE.COACHING &&
      Number(this.Session?.lesson?.UnitId) === this.Id &&
      this.Session?.isPastLesson()
    ) {
      status = LP_STATUS.COMPLETE
    }

    if (status !== LP_STATUS.LOCKED && this.Type === LP_UNIT_TYPE.COACHING) {
      status =
        status === LP_STATUS.COMPLETE && this.LessonStatus === LP_UNIT_LESSON_STATUS.NOT_YET_STARTED
          ? LP_STATUS.UNLOCKED
          : this.LessonStatus !== LP_UNIT_LESSON_STATUS.NOT_YET_STARTED
            ? this.LessonStatus || status
            : status
    }

    return status.toLowerCase().includes(LP_STATUS.COMPLETE) ? LP_STATUS.COMPLETE : status
  }

  get GrammarItem() {
    return removeAllTags(this.unit?.grammaritem)
      .split(',')
      .filter((i) => i.trim())
      .join(',')
  }

  get Vocabularies() {
    return removeAllTags(this.unit?.vocabexpressions)
      .split(',')
      .filter((i) => i.trim())
      .join(',')
  }

  get Title() {
    let title = this.unit?.fullname

    if (this.IsCheckpoint) {
      title = this.CheckpointTitle
    }

    return this.IsCourseMetaData ? LP_COURSE_META_CUSTOM_TITLE : title
  }

  get DetailsType() {
    return this.IsCourseMetaData ? LP_STATUS.COURSE_META : DETAILS_TYPE_DEFAULT
  }

  get Completion() {
    return parseInt(this.unit?.opcompletion ?? '0', 10)
  }

  get Type() {
    return this.unit?.unittype
  }

  get HasContractEnded() {
    return this.PathContractEnded || this.StudentProgram?.HasContractEnded
  }

  get IsLesson() {
    return [LP_UNIT_TYPE.LESSON, LP_UNIT_TYPE.INSTRUCTIONAL].indexOf(this.Type || '') >= 0 && !this.IsCourseMetaData
  }

  get IsReview() {
    return this.Type === LP_UNIT_TYPE.REVIEW
  }

  get IsCulture() {
    return this.Type === LP_UNIT_TYPE.CULTURE
  }

  get IsCheckpoint() {
    return this.Type === LP_UNIT_TYPE.CHECKPOINT
  }

  get IsCoaching() {
    return this.Type === LP_UNIT_TYPE.COACHING
  }

  get IsCurriculumTest() {
    return this.Type === LP_UNIT_TYPE.DEFAULT && this.Title?.includes('Curriculum Test')
  }

  get IsNotYetStarted() {
    return this.FlexStatus === LP_STATUS.NOT_YET_STARTED && this.Status !== LP_STATUS.COMPLETE
  }

  get IsInProgress() {
    return this.FlexStatus === LP_STATUS.IN_PROGRESS && this.Status !== LP_STATUS.COMPLETE
  }

  get IsIncompleteUnit() {
    return this.FlexStatus !== LP_STATUS.COMPLETE && this.Status !== LP_STATUS.COMPLETE
  }

  get HeaderTitle() {
    // some are using an em dash(longer dash) then en Dash (regular dash i.e. minus)
    return this.IsCourseMetaData ? this.Title : this.Title?.split(/-|–/g)?.[0]
  }

  get BodyTitle() {
    let title = this.Title?.split('-')?.[1]

    if (this.IsLesson) {
      title = this.LessonTitle
    }

    return this.IsCourseMetaData ? undefined : (title ?? this.Title)
  }

  get BodyTitleClean() {
    const fullname = this.BodyTitle
    let cardTitle = (fullname || '').replace('-', '{toSplit}').split('{toSplit}').pop()

    return cardTitle?.trim() ?? ''
  }

  get LessonTitle() {
    const unitNumber = parseInt(this.Title?.split('-')?.[0].replace('Unit ', '') || '0', 10)
    return `${
      isNaN(unitNumber) ? `` : `#${parseInt(this.Title?.split('-')?.[0].replace('Unit ', '') || '0', 10)} - `
    }${this.Title?.replace(/^Unit[0-9\s]*.-/, '')}`
  }

  get CheckpointTitle() {
    return this.unit?.fullname
  }

  get LCSTitle() {
    return this.IsLCSZero
      ? (this.Title?.split(':')?.[0] ?? 'Live Introduction Session').replace(/(^\w|\s\w)/g, (m) =>
          this.StudentProgram?.program?.Language?.toLowerCase() === LP_LANGUAGE_FRENCH?.toLowerCase()
            ? m
            : m.toUpperCase()
        )
      : this.Type === LP_UNIT_TYPE.COACHING
        ? (this.Title?.split(':')?.[0] ?? 'Live coaching session')
        : this.BodyTitle
  }

  get Rounds() {
    return this.unit?.rounds ?? []
  }

  get EntryPointUrl() {
    if (this.IsIndustrySpecificContent) {
      return this.Activities?.find((Activity) => Activity.Type === CULTURE_ACTIVITY_TYPE)?.Url
    }

    if ((this.Type === LP_UNIT_TYPE.CULTURE && this.SelectedLanguage) || this.IsCurriculumTest) {
      return this.Activities?.find(
        (Activity) => Activity.Type === CULTURE_ACTIVITY_TYPE && Activity.LanguageCode === this.SelectedLanguage
      )?.Url
    }

    // Generate continue url by looking for the first inprogress round
    if (
      this.Type === LP_UNIT_TYPE.LESSON &&
      (this.Status === LP_STATUS.NOT_YET_STARTED || this.Status === LP_STATUS.IN_PROGRESS || this.IsRedo)
    ) {
      return this.Rounds.find(
        (round) => round.status === LP_STATUS.IN_PROGRESS || round.status === LP_STATUS.NOT_YET_STARTED
      )?.url
    }

    const defaultUrl = this.Activities?.find(
      (Activity) => Activity.Type === FLEX_ACTIVITY_LINK || Activity.Type === CURRICULUM_TEST_ACTIVITY_TYPE
    )?.Url

    if (this.Type === LP_UNIT_TYPE.COACHING && !defaultUrl) {
      return this.Rounds?.[0]?.url
    }

    return defaultUrl
  }

  get ActionPlanUrl() {
    return (
      this.Activities?.find((Activity) => Activity.Type === FLEX_ACTIVITY_LINK)?.ActionPlanUrl || this.CheckpointUrl
    )
  }

  get ResetUrl() {
    const activity = this.IsIndustrySpecificContent
      ? this.Activities?.find((Activity) => Activity.Type === CULTURE_ACTIVITY_TYPE)
      : this.Activities?.find((Activity) => Activity.Type === FLEX_ACTIVITY_LINK)
    return activity?.ResetUrl || activity?.Url
  }

  get ActivePage() {
    return this.IsIndustrySpecificContent
      ? this.Activities?.find((Activity) => Activity.Type === CULTURE_ACTIVITY_TYPE)?.ActivePage
      : undefined
  }

  get IsCoachingActive() {
    return this.IsCoaching && this.Status === LP_STATUS.UNLOCKED
  }

  get IsBooked() {
    return this.IsUnitBooked || this.StudentSessions?.HasOngoingLPSession
  }

  get IsBookedFromSameLP() {
    return this.IsBooked && this.StudentSessions?.OngoingLPSession?.lesson?.LPId === this.LPId
  }

  get HasOngoingSession() {
    return Boolean(this.StudentSessions?.OngoingLPSession)
  }

  get IsUnitBooked() {
    return Number(this.StudentSessions?.OngoingLPSession?.lesson?.UnitId) === this.Id
  }

  get MatchedSession() {
    return this.StudentSessions?.Sessions?.sort((a, b) => {
      return new Date(b.StartTime || 0).getTime() - new Date(a.StartTime || 0).getTime()
    }).find((session) => {
      return (
        parseInt(session.Materials?.[0]?.Chapter ?? '0', 10) === this.Id ||
        (Number(session.lesson?.UnitId) === this.Id && session.lesson?.Attendance !== 'Absent')
      )
    })
  }

  get Session() {
    const session = this.MatchedSession || this.StudentSessions?.OngoingLPSession
    if (session && !session.lesson?.ProgramName) {
      // needed for launching room as ProgramName === RoomName
      session.OverrideProgramName = this.StudentProgram?.program?.ProgramName || ''
    }
    return session
  }

  get SubmittedSession() {
    return this.StudentSessions?.Sessions?.sort((a, b) => {
      return new Date(a.StartTime || 0).getTime() - new Date(b.StartTime || 0).getTime()
    }).find((session) => {
      return (
        (parseInt(session.Materials?.[0]?.Chapter ?? '0', 10) === this.Id ||
          (Number(session.lesson?.UnitId) === this.Id &&
            // submitted session must not be Absent
            session.lesson?.Attendance !== 'Absent' &&
            // must have a lesson registration submission
            session.Materials?.length)) &&
        session.isPastLesson()
      )
    })
  }

  get IsLCSZero() {
    return this.unit?.order === 1 && this.unit?.unittype === LP_UNIT_TYPE.COACHING
  }

  get LastAttemptDate() {
    return this.unit?.latestattemptdate || ''
  }

  get AITutorLink() {
    const aiTutor = this.unit?.ai_tutor_link?.[0]
    if (aiTutor?.url) {
      return aiTutor
    }
    return undefined
  }

  set CoachingCount(count: number) {
    this.LCSCount = count
  }

  set FlexUnit(IsFlex: boolean) {
    this.IsFlex = IsFlex
  }

  set Language(lang: string) {
    this.SelectedLanguage = lang
  }

  set Unlimited(unli: boolean) {
    this.UnlimitedLCS = unli
  }

  set SiblingCheckpointUrl(url: string) {
    this.CheckpointUrl = url
  }

  set EnrolmentCompleted(value: boolean) {
    this.DisableLCS = value
  }

  set Set3rdPartyMaterial(value: boolean) {
    this.Is3rdPartyMaterial = value
  }

  set Order(value: number) {
    this.IndexOrder = value
  }

  generateAITutorLink(
    options: { targetLang: string; userId: string; level: number; interfaceLang?: string },
    myTutorTokenLink?: string
  ) {
    const { interfaceLang, userId, level, targetLang } = options
    const validLang = {
      en: 'en',
      fr: 'fr',
      es: 'es',
      de: 'de',
      'es-MX': 'es',
    }

    let lang = validLang[interfaceLang ?? '']
    if (!lang) {
      lang = targetLang
    }
    const aiTutor = this.unit?.ai_tutor_link?.[0]
    if (aiTutor?.url) {
      const queryStrings = `&user_id=${userId}&level=${level}&interface_lang=${lang}`

      if (myTutorTokenLink) {
        return `${myTutorTokenLink}&path=${aiTutor?.url.replace(MYTUTOR_BASE_URL, '')}${queryStrings}`
      }

      return `${aiTutor?.url}${queryStrings}`
    }
    return undefined
  }
}

export default PortalLearningPathUnit
