import PortalLearningPath from '@classes/LearningPath'
import MyBerlitzLessons from '@classes/Lessons'
import PortalPrograms from '@classes/Programs'
import PortalStudentProfile from '@classes/StudentProfile'
import { LMSLearningPlans } from '@interfaces/LearningPath'
import { ELECTIVE } from '@utils/constants'
import { LP_STATUS } from '@utils/constants/learningPath'
import { IsFlex } from '@utils/helpers/material'
import { getPermissionFromStorage } from '@utils/helpers/permissions'

class PortalLearningPaths {
  learningPaths?: LMSLearningPlans[]
  StudentPrograms?: PortalPrograms
  StudentProfile?: PortalStudentProfile
  StudentSessions?: MyBerlitzLessons
  ElectivesFlag?: boolean
  cachedLearningPaths?: PortalLearningPath[]

  constructor(
    learningPath?: LMSLearningPlans[],
    StudentProfile?: PortalStudentProfile,
    StudentSessions?: MyBerlitzLessons
  ) {
    this.learningPaths = learningPath
    this.StudentProfile = StudentProfile
    this.StudentPrograms = StudentProfile?.PortalPrograms
    this.StudentSessions = StudentSessions
    this.ElectivesFlag = getPermissionFromStorage('flag::GERMAN5-8-feature')
    this.cachedLearningPaths = undefined
  }

  get LearningPaths() {
    const LPs =
      this.cachedLearningPaths ||
      this.learningPaths?.map((path) => {
        const regMaterial = this.StudentProfile?.Materials?.[path.lpid]
        const isFlex = IsFlex(regMaterial)
        const electiveMaterialIds = isFlex
          ? Object.keys(this.StudentProfile?.Materials || {}).filter(
              (key) =>
                regMaterial?.RegistrationId === this.StudentProfile?.Materials?.[key]?.RegistrationId &&
                regMaterial?.LPID !== this.StudentProfile?.Materials?.[key]?.LPID &&
                this.StudentProfile?.Materials?.[key]?.IsElective
            ) || ''
          : []

        const fMaterial =
          this.ElectivesFlag && regMaterial?.IsElective && isFlex
            ? this.learningPaths?.find((lp) => lp.lpid === regMaterial?.FoundationMaterialId)
            : undefined

        const eMaterials =
          this.ElectivesFlag && electiveMaterialIds?.length && isFlex
            ? this.learningPaths?.filter(({ lpid }) => electiveMaterialIds.includes(lpid))
            : []

        const TestMaterials = (regMaterial?.FromRegistration?.Materials ?? [])
          .filter(({ IsTest }) => IsTest)
          .reduce((p: PortalLearningPath[], cur) => {
            const lpRes = this.learningPaths?.find((lp) => lp.lpid === cur.LPID)
            return lpRes ? p.concat(new PortalLearningPath(lpRes, this.StudentProfile, this.StudentSessions)) : p
          }, [])

        return new PortalLearningPath(
          path,
          this.StudentProfile,
          this.StudentSessions,
          eMaterials,
          fMaterial,
          TestMaterials
        )
      })
    this.cachedLearningPaths = LPs
    return LPs
  }

  get ValidPaths() {
    return this.LearningPaths
  }

  get FlexPaths() {
    return this.LearningPaths?.find((Path) => Path.IsFlex)
  }

  get IsReturningStudent() {
    return this.LearningPaths?.some((Path) =>
      Path.Units.some((unit) =>
        unit.Rounds.some(({ status }) => status === LP_STATUS.IN_PROGRESS || status === LP_STATUS.COMPLETE)
      )
    )
  }

  get RecentLearningPath() {
    const key = `${this.StudentProfile?.SFId}-selectMostRecentLP`
    const shouldGetRecent = localStorage.getItem(key)
    const recentPath = this.LearningPaths?.find((lp) => lp.IsFlex && !lp.IsPreviousLP)?.learningPath?.lpid

    if (recentPath) {
      localStorage.removeItem(key)
    }

    return shouldGetRecent ? recentPath : undefined
  }

  get NeedsElectives() {
    return (
      this.ElectivesFlag &&
      this.ValidPaths?.find((path) => {
        return path.Material?.ElectiveRequired === ELECTIVE.REQUIRED && !path.Material.ElectiveMaterials?.length
      })
    )
  }

  getLearningPathById = (LPId: string) => this.LearningPaths?.find((Path) => Path.Id === LPId)
  getSelectedLearningPath = (LPId?: string) => {
    let match =
      this.ValidPaths?.find((Path) => Path.learningPath?.lpid === LPId) || (LPId ? null : this.ValidPaths?.[0])

    return [match].filter(Boolean)
  }

  hasNonFlexPaths = (Paths?: (PortalLearningPath | null | undefined)[]) => {
    return Boolean(Paths?.find((path) => !path?.IsFlex))
  }
}

export default PortalLearningPaths
